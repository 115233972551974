@import "../colors.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  width: 100vw !important;
  height: 100vh !important;
  margin: 0 !important;
  padding: 1.14rem;
}

.steps {
  margin-bottom: 2rem;
}
