small {
  text-align: left;
  font-weight: bold;
  color: red !important;
  font-size: 1.14em !important;
  position: relative;
  top: -10px;
  display: inline-block !important;
  margin: 0 0 0 0.35rem !important;
}
