@import "../typografy";
@import "../colors";

.myInput {
  text-align: left !important;
  color: $grey-label !important;
  margin-bottom: 1.14rem !important;

  .ui.input {
    width: 100%;
  }

  span {
    margin: 0 0 0.28rem 0 !important;
    font-weight: 400 !important;
    color: $grey-label !important;
    display: inline-block !important;
    font-size: 1rem !important;
  }

  input {
    border-radius: 0.7rem !important;
    transition: all 0.2s ease-in-out;
    align-self: flex-end !important;
    // display: block;
    &:focus {
      border: 1px solid $primary-blue-light !important;
      border-radius: 8px;
    }

    &:hover {
      cursor: pointer;
      border: 1px solid $grey-tab-hover !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &::selection {
      background: $grey-1 !important;
      color: $grey-5 !important;
      -webkit-text-fill-color: $grey-5 !important;
    }

    &[name="password"]:hover {
      &::placeholder {
        font-size: 0.9em;
        color: $grey-placeholder !important;
        margin-top: 10px !important;
      }
    }

    &::placeholder {
      color: $grey-placeholder !important;
      font-size: 0.9em;
      font-style: italic;
    }

    i {
      pointer-events: visible !important;
      cursor: pointer !important;
    }

    &[list] {
      margin-left: auto;
      margin-right: auto;

      max-width: 500px;
      background: $grey-1;
      // padding: 20px 20px 20px 20px;
      font: 12px Arial, Helvetica, sans-serif;
      color: #666;
    }
  }
}
