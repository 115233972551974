@import "../typografy";
@import "../colors";

.myActive {
  background: $grey-2 !important;
  color: $grey-5 !important;
}

.myDisabled,
.disabled {
  background: #979ba2 !important;
  color: $grey-5 !important;
}

.myDisabled:hover,
.disabled:hover {
  color: $grey-1 !important;
}

.paymentContainer {
  background-color: $white;
  border-radius: 1rem;
  padding: 0 1.14rem !important;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;

  .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.containerPaymentMethod {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

  background: $grey-2;
  border-radius: 0 0 0.8rem 0.8rem;

  margin: 0 10rem 0 10rem !important;
}

.bankSlipContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

  p {
    text-align: left;
    font-size: 1em;
    width: 60% !important;
  }

  svg {
    color: $grey-5 !important;
    width: 40% !important;
    text-align: right !important;
  }
}

.creditCardContainer {
  width: 400px !important;
  padding: 1.14rem 0;
  margin: 2.28rem !important;

  span {
    color: $grey-5 !important;
  }

  .creditCardGrid {
    margin: 1.14rem 0 0 0 !important;
  }
}

.policyPrivacy {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 1.14rem !important;

  div {
    display: flex !important;
    margin-right: 0.28rem;
  }

  a:hover {
    font-weight: 600;
  }
}

small {
  top: 0;
}

.recaptcha {
  div {
    margin: auto !important;
  }
}

.containerPurchaseSummary {
  background-color: $white;
  max-width: 55vw !important;
  margin: 1.14rem auto !important;
  border-radius: 1.14rem !important;
  padding: 1.14rem !important;

  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;

  & > svg:first-child {
    color: $complementary-red;

    &:hover {
      color: $complementary-dark-red;
    }
  }

  h1 {
    line-height: 2.28rem;
    margin: 0 0 1.14rem 0 !important;
    text-align: center;
  }

  button {
    margin: auto !important;
  }
}

@media only screen and (min-width: 767px) {
  .creditCardContainer {
    background: $grey-5 !important;
    border-radius: 0.6rem;
    width: 400px !important;
    box-shadow: 0px 12px 22px #002f484e;

    span {
      color: $grey-2 !important;
    }

    div {
      color: $grey-3 !important;
    }

    input {
      margin-bottom: 0 !important;
      background: transparent !important;
      border: 1px solid $grey-label !important;
      color: $grey-1 !important;

      &:focus {
        border: 1px solid $grey-3 !important;
        border-radius: 8px;
      }

      &:hover {
        cursor: pointer;
        border: 1px solid $grey-3 !important;
      }
    }
  }

  .bankSlipContainer {
    padding: 4.57rem !important;

    svg {
      font-size: 5rem;
    }
  }

  .containerPurchaseSummary > svg:first-child {
    position: absolute !important;
    right: 3.2em;
    top: 1.8em;
  }
}

@media only screen and (max-width: 767px) {
  .bankSlipContainer {
    padding: 2.28rem !important;

    svg {
      display: none;
    }

    p {
      text-align: center;
      font-size: 1.3em;
      width: 100% !important;
    }
  }

  .containerPurchaseSummary {
    max-width: 80vw !important;
    & > svg:first-child {
      position: absolute !important;
      right: 1.2em;
      top: -0.3em;
    }
  }
}
