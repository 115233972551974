@import "../colors";

.notFoundContainer {
  height: 100vh;
  width: 100% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    margin: 0 10%;
  }

  h1 {
    font-size: 9rem;
    line-height: 8rem;
    letter-spacing: normal;
    color: $grey-6 !important;
  }

  h4 {
    font-size: 1.7rem;
    margin: 0.57rem 0 4.57rem 0 !important;
    line-height: 1.7rem;
  }

  button {
    width: 15rem;
    height: 6vh;
    a {
      color: $white !important;
    }
  }
}
