@import "./colors";

.ui.grid > .row {
  padding: 0 !important;
}

.ui.grid > .column:not(.row) {
  padding-top: 0;
  padding-bottom: 0;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: $grey-placeholder !important;
  font-size: 0.9em;
  font-style: italic !important;
}
