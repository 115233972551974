@import "../colors";

.couponContainer {
  margin: 1.14rem 0;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
}

label {
  margin: 1.14rem 0 0 0 !important;
}

.notVerifiedCoupon {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;

  @media only screen and (min-width: 767px) {
    width: 60% !important;
  }

  p {
    font-size: 1.2em;
    text-align: center;
  }

  button {
    margin-top: 0.57rem !important;
  }
}

.verifiedCoupon {
  color: $primary-green !important;
  padding: 2.28rem 0;

  h2 {
    margin: 0.57rem;
    line-height: 2.28rem;
    margin: 1.14rem;
    color: $primary-green !important;
  }

  h4 {
    font-size: 1.2rem;
    margin: 1.14rem;
    line-height: 1.14rem;
  }
}
