@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./colors";

* {
  font-family: "Montserrat", sans-serif;
}

body {
  -webkit-font-smoothing: antialiased !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li {
  color: $grey-5 !important;
  font-family: "Montserrat", sans-serif !important;
}

button,
a {
  font-family: "Montserrat", sans-serif !important;
}

h1 {
  font-size: 2em;
  line-height: 4.8em;
  font-weight: 600;
  letter-spacing: -0.1em;
  margin: 0 !important;
}

h2 {
  font-size: 1.8em;
  line-height: 2.8em;
  font-weight: 600;
}

h3 {
  font-size: 1.6em;
  line-height: 2.8em;
  font-weight: 600;
}

h4 {
  font-size: 1.4em;
  line-height: 2em;
  font-weight: normal;
}

h5 {
  font-size: 1.2em !important;
  line-height: 1.8em !important;
  font-weight: normal !important;
  font-style: oblique !important;
}

h6 {
  font-size: 1em;
  letter-spacing: 0.1em;
  line-height: 1.4em;
  font-weight: normal;
}

p {
  font-size: 0.9em;
}

.ui.menu .ui.dropdown .menu > a.item,
div[role="option"] {
  transition: all 0.2s ease-in-out !important;
}

a {
  transition: all 0.2s ease-in-out !important;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 2.2em;
    line-height: 4.8em;
    font-weight: 600;
    letter-spacing: -0.1em;
    margin: 0 !important;
  }

  h2 {
    font-size: 2em;
    line-height: 2.8em;
    font-weight: 600;
  }

  h3 {
    font-size: 1.8em;
    line-height: 2.8em;
    font-weight: 600;
  }

  h4 {
    font-size: 1.6em;
    line-height: 2em;
    font-weight: normal;
  }

  h5 {
    font-size: 1.4em !important;
    line-height: 1.8em !important;
    font-weight: normal !important;
    font-style: oblique !important;
  }

  h6 {
    font-size: 1.2em;
    letter-spacing: 0.1em;
    line-height: 1.4em;
    font-weight: normal;
  }

  p {
    font-size: 0.9em;
  }
}
