@import "../typografy";
@import "../colors";

.button {
  background: $primary-blue !important;
  font-family: "Montserrat", sans-serif !important;
  flex-grow: 0 !important;

  text-transform: uppercase !important;
  font-size: 1em !important;
  color: $white !important;
  border-radius: 0.7rem !important;
  border: none;
  outline: none;
  transition: background-color 0.3s ease-in-out !important;

  & > svg {
    margin-left: 0.57rem;
  }

  &.icon.button {
    padding: 0.825rem 2.125rem 0.825rem 1rem !important;
    &.onlyIcon {
      padding: 0.825rem !important;
    }
  }
  & > i {
    background-color: transparent !important;
    margin: 0 1rem !important;
  }

  &.danger {
    background-color: #ff4d4d !important;
    color: $white !important;
  }

  &:hover {
    background: $primary-green !important;
  }

  &:active {
    background-color: $primary-green !important;
  }
  // Disabled Buttons
  &.disabled {
    background-color: $primary-green !important;
    cursor: not-allowed !important;
    color: $white !important;
  }

  @keyframes expand {
    0% {
      transform: translateX(300px);
      max-width: 0;
      opacity: 0;
    }
    25% {
      max-width: 100px;
      transform: translateX(200px);
    }
    50% {
      max-width: 200px;
      transform: translateX(100px);
    }
    100% {
      transform: translateX(0);
      max-width: 300px;
      opacity: 1;
    }
  }
  &.small {
    background-color: transparent !important;
    padding: 0.5rem !important;
    & > i {
      background: $primary-blue !important;
      border-radius: 0.5rem !important;
    }
    & > span {
      background-color: transparent !important;
      color: transparent !important;
      overflow: hidden;
      white-space: nowrap;
    }
    &:hover {
      background-color: $primary-blue !important;
      opacity: 0.8;
      & > span {
        color: $white !important;
        animation: expand 0.6s ease-in-out !important;
      }
    }
  }
  &.top {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  &.bottom {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  // Compact Button Styles
  &.compact {
    &.onlyIcon.icon {
      padding: 0.625rem !important;
    }
    background-color: $grey-1;
    color: $grey-4;
    &:hover {
      background-color: $primary-blue-light;
      color: $white;
    }

    &:active {
      background-color: $primary-green;
      color: $white;
    }
  }

  // Highlight Button Styles
  &.highlight {
    background-color: $primary-green !important;

    &:hover {
      background-color: $primary-green-light !important;
    }

    &:active {
      background-color: $primary-blue !important;
    }
  }

  &.secondary {
    background-color: $grey-3 !important;
    &:hover {
      background-color: $primary-green-light !important;
    }
  }

  &.info {
    background-color: $white !important;
    &:hover {
      background-color: $primary-green-light !important;
    }
  }
}

.width100 {
  width: 100% !important;
}

.width75 {
  width: 75% !important;
}

.width50 {
  width: 50% !important;
}

.width25 {
  width: 25% !important;
}
