// JDREL Greyscale colors
$white: #ffffff;
$grey-1: #f6f6f6;
$grey-2: #e1e2e4;
$grey-3: #ccced1;
$grey-4: #b8bbbf;
$grey-5: #50565f;
$grey-6: #3f4146;
$black: #0c0a0a;
$grey-hover-list: rgba(0, 0, 0, 0.3);
$grey-label: #84888f;
$grey-placeholder: #d4d4d4;

$grey-table-head: #999da3;
$grey-tab-hover: #9e9f9f;
$grey-tab-wait: #b8bbbf;
$grey-tab-list: #e9e9ea;
$grey-list-tabs: #f6f6f6;
$grey-bg-board: rgba(255, 255, 255, 0.8);

// JDREL Primary
$primary-green: #0ae2b9;
$primary-green-light: #00f1d0;
$primary-blue: #0077ff;
$primary-blue-light: #2699fb;

// JDREL Secondary
$complementary-yellow: #fdcd0e;
$complementary-magenta: #ff516d;
$complementary-red: #e32209;
$complementary-dark-red: #ad1a06;
