@import "../colors.scss";

.simplePurchaseSummaryContainer {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  text-align: center !important;
  padding: 1.14rem !important;

  .product {
    h4 {
      font-weight: 600;
      line-height: 1rem;
      font-size: 1.3em;
    }
  }

  .containerSummary {
    padding: 1.14rem 0;
    width: 60% !important;

    .listDescriptions,
    .totalDescription {
      text-align: left !important;
    }

    .listValues,
    .totalValue {
      text-align: right !important;
    }
  }
  .discount {
    p {
      color: $primary-green !important;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 1.14rem 0.57rem !important;
    .product h4 {
      font-size: 1em;
    }

    .containerSummary {
      width: 100% !important;
    }
  }
}
