@import "../typografy";
@import "../colors";

.container {
  background-color: $white;
  border-radius: 1rem;
  padding: 0 1.14rem;
  align-content: center !important;
  // height: 100% !important;

  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
}

.heading {
  padding: 1.14rem 0 !important;
}

.productArea {
  padding: 1.14rem 1.14rem 2.28rem !important;
}

.product h4 {
  text-align: left !important;
  font-weight: 600;
  margin: 0 0 0.57rem 0;
  font-size: 1.1em;
}

.productDescription p {
  text-align: left !important;
  display: block !important;
}

.listDescriptions p {
  text-align: left;
  font-weight: 400;
}

.listValues p {
  font-weight: 600;
  text-align: right;
}

.listValues p,
.listDescriptions p {
  &:first-child {
    margin: 1.14rem 0 0.57rem 0;
  }

  &:last-child {
    margin: 0 0 0.57rem 0;
  }
}

.discount {
  color: $primary-green !important;
}

.divider {
  margin: 0.57rem 0 !important;
  display: block;
}

.totalDescription h4 {
  color: $grey-5 !important;
  text-align: left;
  font-weight: 600;
}

.totalValue h4 {
  color: $grey-5 !important;
  text-align: right;
  font-weight: 600;
}

.totalValue,
.totalDescription {
  margin: 0.57rem 0 !important;
}

.loader {
  max-height: 36.5vh !important;

  .product {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .divider {
    margin: 0.57rem !important;
  }
}
