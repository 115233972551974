@import "../colors";

.stepsContainer {
  margin: 1.71rem 0 !important;
}

.stepItem,
.completeStepItem,
.toDoStepItem {
  text-align: center !important;
  padding: 0 !important;

  p {
    font-weight: 600;
  }

  & > svg {
    margin-top: 8px;
  }
}

.stepItem {
  & > svg {
    color: $grey-5;
  }
}

.completeStepItem {
  & > svg {
    color: $primary-green;
  }

  p {
    color: $primary-green !important;
  }
}

.toDoStepItem {
  p {
    color: $grey-4 !important;
  }

  & > svg {
    margin-top: 1.1rem !important;
    color: $grey-4;
  }

  p {
    margin-top: 0.3rem !important;
  }
}
