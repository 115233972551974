@import "../typografy";
@import "../colors";

.mySelect {
  text-align: left !important;
  color: $grey-5 !important;
  margin-bottom: 1.14rem !important;

  .selectIcon {
    overflow: visible;
    position: absolute;
    right: 8px;
    top: 10px;
    color: $grey-5;
  }
}

label {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 0.9rem;

  span {
    margin-left: 0 !important;
    font-weight: 400 !important;
    color: $grey-label !important;
    font-size: 1rem !important;
  }
}

div[role="listbox"] {
  width: 100%;
  min-width: 15ch;
  max-width: 100%;
  cursor: pointer;
  line-height: 1.1;
  border-radius: 0.7rem !important;
  transition: all 0.2s ease-in-out;
  align-self: flex-end !important;
  padding: 0.67857143em 1em;
  border: 1px solid $grey-2 !important;
  outline: none;
  margin-top: 0.28rem !important;

  &:focus {
    border: 1px solid $primary-blue-light !important;
    border-radius: 8px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid $grey-tab-hover !important;
  }

  i {
    pointer-events: visible !important;
    cursor: pointer !important;
    display: none;
  }
}
