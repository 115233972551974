@import "./colors";
@import "./typografy";

body {
  // justify-content: center !important;
  // align-items: center !important;

  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  height: 100vh !important;
  width: 100% !important;

  background-color: $grey-2 !important;
}

* {
  font-family: "Montserrat", sans-serif !important;
}

.containerFormStep {
  background-color: $white;
  border-radius: 1rem;
  padding: 0 1.14rem !important;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  height: 100% !important;
}

.inputsContainer {
  margin-top: 1.14rem;
}

@media only screen and (max-width: 767px) {
  .ui.container {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.containerCommandButtons {
  display: block !important;
  margin: 1.14rem 0 !important;

  button:first-child {
    margin-right: 0.57rem !important;
    background-color: transparent !important;
    color: $grey-5 !important;
    border: solid $grey-4 1px !important;

    svg {
      margin-right: 0.57rem !important;
    }
  }
}
