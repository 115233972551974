@import "../typografy";
@import "../colors";

.heading {
  padding: 0 !important;
  margin: 0 !important;
  color: $grey-5 !important;
  margin: 1.14rem !important;
  display: block !important;
  line-height: 2.28rem !important;
}
