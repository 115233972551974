@import "../typografy";
@import "../colors";

.footer {
  display: block;
  padding: 1.5rem 0;
  margin: 1.14rem 0 !important;
}

.footer a {
  font-weight: 600;
  margin: 2rem auto;
  text-align: center;
  color: $grey-5;
  font-size: 1.2em;
}

.footer a:hover {
  color: $grey-hover-list;
}
