@import "../colors.scss";

// .container {
// padding: 1.14rem;
// }

.containerSuccess {
  background-color: $white;
  border-radius: 1rem;
  min-height: 28rem;
  // padding: 1.14rem;
  align-items: center !important;
  margin-top: 2.27rem;
  width: 80% !important;

  button {
    margin-bottom: 1.14rem !important;
    svg {
      margin-left: 1rem;
    }
    a {
      color: $white !important;
    }
  }
}

.successMessage {
  h2 {
    font-weight: 600;
    color: $primary-green !important;
    line-height: 1.57rem !important;

    margin: 0.57rem 0 !important;
  }

  svg {
    margin: 1.14rem 0 !important;
    min-width: 200px !important;
    color: $primary-green;
  }

  h4 {
    line-height: 1.57rem !important;
    font-size: 1.2rem !important;
  }
}

@media only screen and (min-width: 767px) {
  .successMessage h4 {
    margin: 0 10rem 1.14rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .successPageContainer {
    padding: 1.14rem;

    .successMessage h4 {
      margin: 0 2.5rem 1.14rem !important;
    }
  }
}
